@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

td,
th {
  border: 0.2px solid #24272b;
  text-align: left;
  padding: 6px;
  white-space: nowrap;
}



/* tr:hover {
  font-style: italic;
  background-color: #3a7bd6;
} */

/* thead tr:first-child {
  background-color: #3a7bd6;
} */

@keyframes blink {
  0%,
  100% { opacity: 0; }
  50% { opacity: 1; }
}

.red-dot {
  width: 1rem;
  height: 1rem;
  background-color: red;
  border-radius: 50%;
  animation: blink 2s infinite;
}

.green-dot {
  width: 1rem;
  height: 1rem;
  background-color: green;
  border-radius: 50%;
  animation: blink 2s infinite;
}

.auth-input {
  width: 90%;

  /* height: fit-content; */
  border-radius: 12px;
  padding: 1rem 0.1rem 1rem 0.5rem;
  background-color: transparent;
}

.auth-pass-div {
  display: flex;
  align-items: center;
  gap: 1px;
  width: 90%;
  border-radius: 12px;
  background-color: transparent;
}

.auth-pass-div .auth-pass {
  width: 92%;
  background-color: transparent;
  border: none;
  border-radius: 12px;
  padding: 1rem;
}

.date-input::-webkit-calendar-picker-indicator {
  width: 20px;
  height: 20px;
  background-color: #fff;
  cursor: pointer;
}

.auth-pass-div .auth-pass:focus {
  width: 90%;
  background-color: transparent;
  outline: none;
}

.dapp-hero {
  background-image: url('./assets/images/Animated-Shape.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}

.dapp-hero-t2 {
  background-image: url('./assets/images/Animated-Shape-t2.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}

.dapp-hero-t3 {
  background-image: url('./assets/images/Animated-Shape-t3.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}

@keyframes upDownMotion {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes leftRightMotion {
  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20px);
  }
}

.image-bg {
  animation: upDownMotion 2s ease-in-out infinite;
}

.partner-logo {
  transition: opacity 0.3s ease;
}

.partner-logo:hover {
  opacity: 1;
}

.partner-logo-container:hover .partner-logo:not(:hover) {
  opacity: 0.2;
}

.dapp-main {
  background-image: url('./assets/images/wave-main.svg');
  background-color: whitesmoke;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}

.dapp-main-t2 {
  background-image: url('./assets/images/wave-main-t2.svg');
  background-color: whitesmoke;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}

.dapp-main-t3 {
  background-image: url('./assets/images/wave-main-t3.svg');
  background-color: whitesmoke;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}

.p-feature-card {
  animation: leftRightMotion 2.5s ease-in-out infinite;
}

::-webkit-scrollbar {
  width: 2px; /* Set the scrollbar width */
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #4a90e2; /* Set the thumb color */
  border-radius: 4px; /* Set the thumb border radius */
}

::-webkit-scrollbar-track {
  /* background: #6e6e6e; */
  background: transparent;
  border-radius: 4px;
}

@keyframes slideDown {
  0% {
    transform: translateY(-600%);
  }

  100% {
    transform: translateY(200%);
  }
}

.text-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  animation: slideDown 5s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  animation: scroll 30s linear infinite;
}

@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

@keyframes pause {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.animate-heartbeat {
  animation: heartbeat 1s ease-in-out 3s infinite, pause 5s linear 3s infinite;
}

.test-slider {
  max-width: 100vw;
  overflow: hidden;
}

.art-image-animation-1 {
  position: absolute;
  opacity: 0;
  animation: flowFromTop 30s linear infinite;
  animation-delay: 3s;
}

.art-image-animation-2 {
  position: absolute;
  opacity: 0;
  animation: flowFromTop 30s linear infinite;
  animation-delay: 7s;
}

.art-image-animation-3 {
  position: absolute;
  opacity: 0;
  animation: flowFromTop 20s linear infinite;
  animation-delay: 1s;
}

.art-image-1 {
  top: -10%;
  left: 10%;
}

.art-image-2 {
  top: -20%;
  left: 75%;
}

.art-image-3 {
  top: -20%;
  left: 50%;
}

@keyframes flowFromTop {
  0% {
    opacity: 1;
    transform: translateY(-10vh);
  }

  10% {
    opacity: 1;
    transform: translateY(10vh);
  }

  20% {
    opacity: 1;
    transform: translateY(20vh);
  }

  30% {
    opacity: 1;
    transform: translateY(30vh);
  }

  40% {
    opacity: 0.8;
    transform: translateY(40vh);
  }

  50% {
    opacity: 0.6;
    transform: translateY(50vh);
  }

  60% {
    opacity: 0.4;
    transform: translateY(60vh);
  }

  70% {
    opacity: 0.2;
    transform: translateY(70vh);
  }

  100% {
    opacity: 0;
    transform: translateY(80vh);
  }
}

/* RadialChart.css */
.radial-chart {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  overflow: hidden;
}

.radial-chart span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (min-width: 600px) {
  .test-slider {
    max-width: calc(100vw - 150px);
    overflow: hidden;
  }
}

@media only screen and (min-width: 992px) {
  .test-slider {
    max-width: calc(100vw - 300px);
    overflow: hidden;
  }
}
