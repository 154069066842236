@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,400;0,600;1,100;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,400;0,600;1,100;1,400&family=Kanit:ital,wght@0,100;0,200;0,400;0,600;0,700;0,800;1,100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* stylelint-disable-next-line */
@layer base {
  :root {
    /* --color-mainBg: 210deg 40% 98%; */
    /* --color-subtleBg: 0deg 0% 95.3%; */
    --color-mainBg: 0deg 0% 100%;
    --color-subtleBg: 270deg 4.3% 97.3%;
    /* --color-mainHeading: 210deg 79% 57%; */
    --color-mainHeading: 218deg 94% 48%;
    --color-subHeading: 215deg 19% 35%;
    --color-darkerHeading: 224deg 44% 25%;
    --color-content: 222deg 38% 20%;
    --color-subText: 212deg 27% 51%;
    --color-link: 210deg 79% 57%;
    --color-notice: 50deg 100% 65%;
    --color-accent1: 240deg 95.8% 90.6%;
    --color-accent2: 300deg 83.8% 78.2%;
  }

  :root[data-theme="dark"] {
    /* --color-mainHeading: 210deg 79% 57%; */
    /* --color-subtleBg: 0deg 0% 10%; */
    --color-mainBg: 0deg 0% 0%;
    --color-subtleBg: 220deg 6% 7%;
    --color-mainHeading: 218deg 94% 48%;
    --color-subHeading: 216deg 25% 60%;
    --color-darkerHeading: 210deg 40% 98%;
    --color-content: 0deg 0% 100%;
    --color-subText: 212deg 27% 51%;
    --color-link: 210deg 79% 57%;
    --color-notice: 50deg 100% 65%;
    --color-accent1: 210deg 100% 20%;
    --color-accent2: 209deg 95% 11%;
  }
}

* {
  @apply transition-colors duration-200;
}

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif,
    'Kanit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* font-family: 'Barlow', sans-serif; */

  /* font-family: 'Kanit', sans-serif; */
}
